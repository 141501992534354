



























































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import __ from '@/helpers/__'
import PageHeader from '@/components/layout/PageHeader.vue'
import { getGraphInfo } from '@/shared/helpers/statistics/widgetStatistics'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import { GeneralRoutes } from '@/router/routes/general'
import Invoice, { InvoiceSharedTable } from '@/modules/invoices/models/invoice.model'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter'
import { InvoiceStatusKeys } from '@/shared/configs/invoice/invoice.config'
import refreshTable from '@/shared/helpers/data-table/refresh-table'
import { Route } from 'vue-router'
import DashboardInfo from '@/modules/dashboard/models/dashboard-info.model'
import DashboardService from '@/modules/dashboard/dashboard-service'
import highlightOverdue from '@/shared/helpers/data-table/highlight-overdue';
import DashBoardRequests from '@/views/dashboard/DashBoardRequests.vue';
import { tableCount } from '@/helpers/tableCount';
import DashBoardDocuments from '@/views/dashboard/DashBoardDocuments.vue';
import hasPermission from '@/shared/helpers/has-permission';

@Component({
  components: { DashBoardDocuments, DashBoardRequests, DataTable, PageHeader },
  methods: { __, hasPermission }
})
export default class Dashboard extends Vue {
  table: DataTableBase | any = null
  dashboardInfo: DashboardInfo | null = null
  loading = true
  graphInfo: any = null
  GeneralRoutes = GeneralRoutes
  tableCount = tableCount
  showDocumentsTable = false

  @Watch('$route', { immediate: true, deep: true })
  private onUrlChange(newVal: Route): void {
    if (newVal.name === GeneralRoutes.dashboard && this.$refs.table) {
      refreshTable(this.$refs.table)
    }
  }

  async getDashboardInfo() {
    if (!hasPermission('dashboards.info')) {
      return
    }
    this.dashboardInfo = await DashboardService.getDashboardInfo().then((response: any) => {
      this.graphInfo = getGraphInfo(response.paidInvoiceMonthlySumsWithoutVat)

      return response
    })
  }

  createUnpaidInvoicesTable() {
    if (!hasPermission('invoices.view')) {
      return
    }
    this.table = new DataTableBase()
      .setId(tableCount.tableID.dashboardInvoices)
      .setModel(InvoiceSharedTable)
      .setHeading(__('views.dashboard.unpaid'))
      .setEndpoint('/invoices')
      .setRowClassCallback(highlightOverdue)
      .setRowClickCallback((item: Invoice) => {
        this.$router.push({ name: GeneralRoutes.invoice, params: { id: item.id.toString() } })
      })
      .setFilter([
        {
          operator: FilterOperators.in,
          type: 'status',
          value: `${InvoiceStatusKeys.unpaid},${InvoiceStatusKeys.partiallyPaid},${InvoiceStatusKeys.notApproved}`
        }
      ])
      .setHeaders([
        new DataTableHeader()
          .setKey('number')
          .setLabel(__('views.invoices.index.table.columns.number'))
          .setLink({ link: GeneralRoutes.invoice, linkParam: 'id' })
          .setPopover('notes'),
        new DataTableHeader()
          .setKey('client.company_name')
          .setLabel(__('views.invoices.index.table.columns.client')),
        new DataTableHeader()
          .setKey('manager.full_name')
          .setLabel(__('views.invoices.index.table.columns.manager')),
        new DataTableHeader().setKey('total').setLabel(__('views.invoices.index.table.columns.total')),
        new DataTableHeader().setKey('totalWithVat').setLabel(__('views.invoices.index.table.columns.totalWithVat')),
        new DataTableHeader().setKey('payed').setLabel(__('views.invoices.index.table.columns.payed')),
        new DataTableHeader().setKey('leftPay').setLabel(__('views.invoices.index.table.columns.leftPay')),
        new DataTableHeader().setKey('date').setLabel(__('views.invoices.index.table.columns.date')),
        new DataTableHeader().setKey('dueDate').setLabel(__('views.invoices.index.table.columns.dueDate')),
        new DataTableHeader()
          .setKey('status')
          .setLabel(__('views.invoices.index.table.columns.status'))
          .setFunctionalClasses((data: InvoiceSharedTable) => `data-table__status data-table__status--${data.status}`)
          .setCustomText((data: InvoiceSharedTable) => {
            if (data.status === 'paid') return __('common-names.paid')

            if (data.status === 'partially-paid') return __('common-names.partially-paid')

            if (data.status === 'not-approved') return __('common-names.unapproved')

            return __('common-names.unpaid')
          })
      ])
  }

  async created(): Promise<void> {
    await this.getDashboardInfo()
    this.createUnpaidInvoicesTable()
  }

  get graphInfoold(): any {
    if (!this.dashboardInfo || !this.dashboardInfo?.paidInvoiceMonthlySumsWithoutVat) return null
    return getGraphInfo(this.dashboardInfo?.paidInvoiceMonthlySumsWithoutVat)
  }

  createNewRequest(): void {
    this.$router.push({ name: GeneralRoutes.requestCreate })
  }
}
