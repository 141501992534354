





























































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import { FieldSizes } from '@/shared/classes/components/form/field'
import Invoice from '@/modules/invoices/models/invoice.model'
import { GeneralRoutes } from '@/router/routes/general'
import http from '@/shared/helpers/http'
import IResponse from '@/shared/interfaces/response.interface'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import _ from 'lodash'
import { InvoiceStatusKeys, InvoiceTypes } from '@/shared/configs/invoice/invoice.config'
import getAll from '@/shared/configs/vuex/get-all.config'
import { mapGetters } from 'vuex'
import User from '../user/models/user.model'
import { UserGetters } from '@/store/modules/user/user.getters'
import InvoicesService from '@/services/InvoicesService';
import SelectOption from '@/shared/classes/components/form/select-option';
import { IServiceItem } from '@/modules/invoices/interfaces/invoice-response.interface';
import IServiceResponse from '@/modules/service/interfaces/service-response.interface';
import LoadingSpinner from '@/shared/components/LoadingSpinner.vue';
import RadioField from '@/shared/components/form/fields/RadioField.vue';
import DropdownField from '@/shared/components/form/fields/DropdownField.vue';
import moment from 'moment/moment';
import EverhourService from '@/services/EverhourService';
import PeriodField from '@/shared/components/form/fields/PeriodField.vue';
import hasPermission from '@/shared/helpers/has-permission';

interface IDatePeriod {
  to: string | null
  from: string | null
}

@Component({
  components: { PeriodField, DropdownField, RadioField, LoadingSpinner, PageHeader, Form },
  methods: { __, hasPermission },
  computed: {
    ...mapGetters({
      user: UserGetters.getUser
    })
  }
})
export default class InvoiceCreate extends Vue {
  itemsBlockKey: number = 0
  FieldSizes = FieldSizes
  InvoiceTypes = InvoiceTypes
  previousItems: IServiceResponse[] = []
  loadingNumber: boolean = false
  validNumber: boolean = false
  useEverhour: boolean = false
  everhourProject: string | null = null
  everhourDate: IDatePeriod = { to: null, from: null }
  everhourLoading: boolean = false
  user!: User
  error: any = null
  everhourError: any = null
  errorMessage: string = ''
  data: Invoice = new Invoice({})
  standardVat: number | null = null
  standardUnit: string | null = null
  standardAmount: number | null = null
  standardPricePerUnit: number | null = null
  vatOptions: SelectOption[] = []
  unitOptions: SelectOption[] = []
  discountTypes: SelectOption[] = [
    new SelectOption().setKey('none').setTitle(__('views.invoices.form.no_discount')),
    new SelectOption().setKey('discount').setTitle(__('views.invoices.form.sum_discount')),
    new SelectOption().setKey('discount_percentage').setTitle(__('views.invoices.form.percent_discount'))
  ]

  loading: boolean = true

  getRedirectRoute() {
    if (this.data.type === InvoiceTypes.draft) {
      return GeneralRoutes.invoicesDraftInner
    }
    if (this.data.type === InvoiceTypes.credit) {
      return GeneralRoutes.invoice
    }
    return GeneralRoutes.invoice
  }

  async submit(): Promise<void> {
    this.error = null
    this.errorMessage = ''
    const payload = _.cloneDeep(this.data)
    payload.discount = payload.discount || 0
    payload.discount_percentage = payload.discount_percentage || 0
    await InvoicesService.createInvoice(payload)
      .then((invoice: Invoice) => {
        if (invoice.id) this.$router.push({ name: this.getRedirectRoute(), params: { id: invoice.id.toString() } })
      })
      .catch((error: any) => {
        this.error = error
        this.errorMessage = error?.response?.data?.message
      })
  }

  private debouncedCheckIfValid = _.debounce(() => this.checkIfValid(), 400)

  debouncedValidityCheck() {
    this.debouncedCheckIfValid()
  }

  itemIsEmpty(item: IServiceResponse) {
    return _.isEqual(item, this.data.newInvoiceItem)
  }

  fillEverhourData() {
    if (!this.everhourProject || !this.everhourDate.from || !this.everhourDate.to) {
      return
    }
    const everhourVat = 21
    if (this.data.items.length === 1 && this.itemIsEmpty(this.data.items[0])) {
      this.data.items = []
    }
    this.everhourLoading = true
    this.everhourError = null
    EverhourService.getData(this.everhourProject, this.everhourDate.from, this.everhourDate.to)
      .then(response => {
        const processedTasks:IServiceResponse[] = []
        response.forEach(task => {
          const processedTask = { ...this.data.newInvoiceItem }
          processedTask.title = task.name
          processedTask.amount = task.amount
          processedTask.price_per_unit = task.price_per_unit ? Number(task.price_per_unit).toFixed(2) : null
          processedTask.price_per_unit_with_vat = (Number(task.price_per_unit) * (100 + everhourVat) / 100).toFixed(2)
          processedTask.vat = everhourVat
          processedTask.unit = task.unit
          processedTasks.push(processedTask)
        })
        this.data.items = [...this.data.items, ...processedTasks]
      })
      .catch((error: any) => {
        this.everhourError = error.response.data.message
      })
      .finally(() => {
        this.everhourLoading = false
      })
  }

  onItemChange(data: IServiceItem | string, item: IServiceResponse): void {
    if (typeof data === 'string') {
      if (typeof item?.title !== 'undefined') {
        item.title = data
      }
      return
    }
    if (typeof data?.amount !== 'undefined') {
      item.amount = data.amount
    }
    if (typeof data?.vat !== 'undefined') {
      item.vat = Number(data.vat)
    }
    if (typeof data?.price_per_unit !== 'undefined') {
      item.price_per_unit = data.price_per_unit
    }
    if (typeof data?.price_per_unit_with_vat !== 'undefined') {
      item.price_per_unit_with_vat = Number(data.price_per_unit_with_vat)
    }
    const foundUnit = this.unitOptions.find(unit => unit.title === data?.unit)
    item.unit = foundUnit?.key || item.unit

    item.total = (Number(item.amount) * Number(item.price_per_unit)).toFixed(2)
    item.total_with_vat = (Number(item.total) * (1 + Number(item.vat) / 100)).toFixed(2)

    this.calculateSums()
  }

  async getInvoiceItemOptions(): Promise<void> {
    const vatOptions = await InvoicesService.getVatOptions()
    vatOptions.forEach(vatItem => {
      this.vatOptions.push(new SelectOption().setKey(vatItem.percentage).setTitle(vatItem.title))
    })

    const unitOptions = await InvoicesService.getUnitOptions()
    unitOptions.forEach(unitItem => {
      this.unitOptions.push(new SelectOption().setKey(unitItem.title).setTitle(unitItem.title))
    })
  }

  removeInvoiceItem(index: number): void {
    this.data.items.splice(index, 1)
    this.itemsBlockKey++
  }

  calculateSums() {
    if (!this.data) {
      return
    }
    let newTotal = 0
    let newTotalWithVat = 0
    let newTotalVat = 0
    const vatRate = (this.data.items.length ? this.data.items[0].vat : 0) || 0
    this.data.items.forEach(item => {
      newTotal += Number(item.total)
      newTotalWithVat += Number(item.total_with_vat)
      newTotalVat += (Number(item.total_with_vat) - Number(item.total))
    })
    const sumDiscount = this.data.discount_type === 'discount' ? Number(this.data.discount) : 0
    const percentDiscount = this.data.discount_type === 'discount_percentage' ? 1 - (Number(this.data.discount_percentage) / 100) : 1
    this.data.total = ((newTotal - sumDiscount) * percentDiscount).toFixed(2)
    this.data.total_with_vat = ((newTotalWithVat - sumDiscount * (1 + vatRate / 100)) * percentDiscount).toFixed(2)
    this.data.total_vat = ((newTotalVat - sumDiscount * (vatRate / 100)) * percentDiscount).toFixed(2)
  }

  @Watch('data.discount_type')
  @Watch('data.discount')
  @Watch('data.discount_percentage')
  onDiscountChange(): void {
    this.calculateSums()
  }

  async createBlankInvoice() {
    await http
      .get(`invoices/get-next-number/${InvoiceTypes.standard}`)
      .then((response: IResponse<IModelResponse>) => {
        this.loadingNumber = true
        this.data.number = response.data
        this.validNumber = true
      })
      .finally(() => {
        this.loadingNumber = false
      })

    this.data.manager_id = this.user.id
    this.data.locale = 'lt'
    this.data.send_email = true
    this.data.status = InvoiceStatusKeys.unpaid
    this.data.type = InvoiceTypes.standard

    if (this?.$route?.params?.data) {
      const data: any = this.$route.params.data
      this.data.manager_id = data.manager_id ? data.manager_id : this.user.id
      this.data.client_id = data.client_id
      this.data.date = data.date || moment(data.date).format('YYYY-MM-DD')
      this.data.notes = data.notes
      this.data.items = data.items || [{ ...this.data.newInvoiceItem }]
    }
  }

  async createCreditInvoice() {
    const invoiceData = await InvoicesService.getInvoice(Number(this.$route.query.invoice_id))
    this.data = new Invoice(invoiceData)
    this.data.items.forEach(item => {
      item.price_per_unit = (-Number(item.price_per_unit)).toFixed(2)
      item.price_per_unit_with_vat = (-Number(item.price_per_unit_with_vat)).toFixed(2)
    })
    this.data.type = InvoiceTypes.credit
    this.data.invoice_id = Number(this.$route.query.invoice_id)
  }

  async created(): Promise<void> {
    await getAll(this.$store)
    await this.getInvoiceItemOptions()
    if (!this.$route.query.invoice_id) {
      await this.createBlankInvoice()
    } else {
      await this.createCreditInvoice()
    }

    this.loading = false
  }

  checkIfValid(): any {
    if (!this.loadingNumber && this.data.number) {
      this.loadingNumber = true
      http
        .get(`invoices/check-is-available/${this.data.number}`)
        .then((response: IResponse<IModelResponse>) => {
          if (!response.data) this.validNumber = false
          else this.validNumber = true
        })
        .finally(() => {
          this.loadingNumber = false
        })
    }
  }

  addInvoiceItem() {
    const newInvoiceItem = { ...this.data.newInvoiceItem }
    if (this.standardUnit) newInvoiceItem.unit = this.standardUnit
    if (this.standardAmount) newInvoiceItem.amount = this.standardAmount
    if (this.standardVat) newInvoiceItem.vat = this.standardVat
    if (this.standardPricePerUnit) newInvoiceItem.price_per_unit = this.standardPricePerUnit
    this.data.items.push({ ...newInvoiceItem })
    this.recalculateItemRow(this.data.items[this.data.items.length - 1], 'vat')
    this.calculateSums()
  }

  clearInvoiceItems() {
    this.data.items = [{ ...this.data.newInvoiceItem }]
    this.itemsBlockKey++
  }

  recalculateItemRow(item: IServiceResponse, field:string) {
    if (field === 'vat' || field === 'price_per_unit' || field === 'amount') {
      item.total = Number(item.amount) * Number(item.price_per_unit)
      item.total_with_vat = item.total * (1 + Number(item.vat) / 100)
      item.price_per_unit_with_vat = Number(item.total_with_vat) / Number(item.amount)
      item.total = item.total.toFixed(2)
      item.total_with_vat = item.total_with_vat.toFixed(2)
    }

    if (field === 'total') {
      item.price_per_unit = Number(item.total) / Number(item.amount)
      item.total_with_vat = Number(item.total) * (1 + Number(item.vat) / 100)
      item.price_per_unit_with_vat = Number(item.total_with_vat) / Number(item.amount)
      item.price_per_unit = item.price_per_unit.toFixed(2)
      item.total_with_vat = item.total_with_vat.toFixed(2)
    }

    if (field === 'total_with_vat') {
      item.total = Number(item.total_with_vat) / (1 + Number(item.vat) / 100)
      item.price_per_unit = Number(item.total) / Number(item.amount)
      item.price_per_unit_with_vat = Number(item.total_with_vat) / Number(item.amount)
      item.price_per_unit = item.price_per_unit.toFixed(2)
      item.total = item.total.toFixed(2)
    }
    item.price_per_unit_with_vat = Number(item.price_per_unit_with_vat).toFixed(2)
    this.calculateSums()
  }

  onItemFieldBlur(item: IServiceResponse, field: keyof IServiceResponse) {
    if (!(field in item)) {
      return
    }
    // @ts-ignore
    item[field] = Number(item[field]).toFixed(2)
  }

  @Watch('data.items', { deep: true })
  onItemsChange(newItems: IServiceResponse[]): void {
    if (!this.previousItems.length) {
      this.previousItems = JSON.parse(JSON.stringify(newItems))
      return
    }
    newItems.forEach((newItem, index) => {
      const oldItem = this.previousItems[index]
      if (!oldItem) return

      const changedField = Object.keys(newItem).find(
        key => newItem[key as keyof IServiceResponse] !== oldItem[key as keyof IServiceResponse]
      )

      if (changedField) {
        this.recalculateItemRow(newItem, changedField)
      }
    })

    this.previousItems = JSON.parse(JSON.stringify(newItems))
  }

  setStandardValues() {
    this.data.items.forEach(item => {
      if (this.standardVat !== null) { item.vat = this.standardVat }
      if (this.standardUnit !== null) { item.unit = this.standardUnit }
      if (this.standardAmount) { item.amount = this.standardAmount }
      if (this.standardPricePerUnit) { item.price_per_unit = this.standardPricePerUnit }
    })
  }
}
